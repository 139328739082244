import Image from 'next/image';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    container: {
        marginTop: theme.spacing(6),
        zIndex: 1,
    },
    alert: {
        zIndex: 1,
        position: 'relative',
    },
    alertTitle: {
        fontWeight: theme.typography.h4.fontWeight,
        marginTop: theme.typography.h4.marginTop,
    },
    background: {
        zIndex: 0,
    },
}));

function Error404() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Image
                src="https://cdn.autohost.ai/static/bitart/background-cottage-stream-day.jpg"
                alt="Fantasy-style cottage in the day time with warm lights pouring out of the windows"
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                className={classes.background}
            />
            <Container maxWidth="sm" className={classes.container}>
                <Alert severity="error" className={classes.alert}>
                    <AlertTitle>
                        Page Not Found
                    </AlertTitle>
                    Sorry, looks like we sent you the wrong way. Click <Link href="/">here</Link> to go back to the
                    home page.
                </Alert>
            </Container>
        </div>
    );
}

export default Error404;